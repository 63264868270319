<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8797 8.68555H83.1214C87.2406 8.68555 90.6084 12.0533 90.6084 16.1725V16.3117H9.39275V16.1725C9.36492 12.0533 12.7327 8.68555 16.8797 8.68555ZM90.3022 16.0334C90.2187 12.109 87.018 8.96387 83.0936 8.96387H16.8519C12.9275 8.96387 9.72674 12.1368 9.64324 16.0334H90.3022Z"
      fill="#333333"
    />
    <path
      d="M9.53906 91.3151V16.1889L90.4543 16.1738L90.3633 91.3151H9.53906Z"
      stroke="#333333"
      stroke-width="0.3"
    />
    <circle cx="28.2681" cy="12.3072" r="1.29976" stroke="#333333" stroke-width="0.3" />
    <circle cx="23.9195" cy="12.3072" r="1.29976" stroke="#333333" stroke-width="0.3" />
    <circle cx="19.5699" cy="12.3072" r="1.29976" stroke="#333333" stroke-width="0.3" />
    <mask
      id="mask0_882_11411"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="9"
      y="16"
      width="82"
      height="76"
    >
      <rect x="9.47559" y="16.2285" width="80.7373" height="74.9316" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_882_11411)">
      <path
        d="M71.0048 75.5204L79.3301 83.8457L81.8861 81.2897L73.5608 72.9644L71.0048 75.5204Z"
        fill="white"
      />
      <path
        d="M73.6693 72.8544C73.6004 72.7855 73.4971 72.7855 73.4283 72.8544L70.8807 75.4019C70.8119 75.4708 70.8119 75.5741 70.8807 75.6429L79.2119 83.9741C79.2464 84.0085 79.2808 84.0085 79.3496 84.0085C79.4185 84.0085 79.4529 84.0085 79.4873 83.9741L82.0349 81.4265C82.0693 81.3921 82.0693 81.3577 82.0693 81.2888C82.0693 81.22 82.0349 81.1856 82.0349 81.1511L73.6693 72.8544ZM79.3152 83.5954L71.225 75.5052L73.5316 73.1986L81.6218 81.2888L79.3152 83.5954Z"
        fill="#333333"
      />
      <path
        d="M83.1019 79.5683C82.7921 79.2585 82.2757 79.2585 81.9659 79.5683L78.4888 83.0454L77.5937 83.9405C77.2839 84.2503 77.2839 84.7667 77.5937 85.0765L105.514 112.996C105.686 113.168 105.823 113.306 106.03 113.409C107.545 114.477 109.679 114.339 111.022 112.996C112.537 111.482 112.537 109.037 111.022 107.523L83.1019 79.5683Z"
        :fill="primaryColors.primary300"
      />
      <path
        d="M83.2061 79.4657C82.8274 79.087 82.2077 79.087 81.829 79.4657L77.4569 83.8378C77.0782 84.2165 77.0782 84.8362 77.4569 85.2149L105.377 113.135C105.549 113.307 105.721 113.445 105.893 113.582C106.582 114.064 107.373 114.34 108.234 114.34C109.301 114.34 110.334 113.926 111.091 113.169C111.849 112.412 112.262 111.379 112.262 110.312C112.262 109.244 111.849 108.212 111.091 107.454L83.2061 79.4657ZM110.885 112.859C110.196 113.548 109.267 113.961 108.268 113.961C107.477 113.961 106.754 113.72 106.134 113.272C105.962 113.169 105.79 113.031 105.652 112.859L77.7323 84.9394C77.4913 84.6985 77.4913 84.2854 77.7323 84.0444L82.1044 79.6722C82.2077 79.5689 82.3798 79.5001 82.552 79.5001C82.7241 79.5001 82.8618 79.5689 82.9995 79.6722L110.919 107.592C111.608 108.281 112.021 109.21 112.021 110.208C112.021 111.207 111.573 112.171 110.885 112.859Z"
        fill="#333333"
      />
      <path
        d="M70.2288 65.9023L63.9287 72.2024L67.0615 75.3352C68.8173 77.0909 71.6058 77.0909 73.3616 75.3352C75.1173 73.5794 75.1173 70.7909 73.3616 69.0351L70.2288 65.9023Z"
        fill="#6A9FF6"
      />
      <path
        d="M70.2285 65.6621L63.6875 72.2031L66.958 75.4736C67.8187 76.3343 68.9892 76.8163 70.2285 76.8163C71.4679 76.8163 72.6384 76.3343 73.499 75.4736C75.2892 73.6835 75.2892 70.7228 73.499 68.9326L70.2285 65.6621ZM73.258 75.2326C72.4662 76.0245 71.3646 76.472 70.2285 76.472C69.0924 76.472 68.0252 76.0245 67.199 75.2326L64.1695 72.2031L70.2285 66.1441L73.258 69.1736C74.9449 70.8605 74.9449 73.5802 73.258 75.2326Z"
        fill="#333333"
      />
      <path
        d="M92.3278 97.7786C88.954 94.818 86.0277 92.2704 85.5458 91.5475C85.4769 91.4098 85.4081 91.3409 85.3736 91.2721C85.6146 91.4098 85.9933 91.6852 86.2687 91.8573C87.2327 92.5458 87.5425 92.718 87.7491 92.5458C87.9212 92.4081 87.8179 92.1327 87.3704 91.4786C85.4769 88.6901 78.0408 84.0425 77.6965 83.8359L77.5244 84.1114C77.5933 84.1458 85.2359 88.9311 87.095 91.6507C87.2671 91.9261 87.3704 92.0983 87.4392 92.2016C87.1982 92.0639 86.7851 91.7884 86.5097 91.5819C85.5802 90.9278 85.2704 90.7212 85.0638 90.8934C84.8917 91.0311 85.0294 91.3065 85.2704 91.7196C85.7523 92.477 88.5753 94.9557 92.1212 98.0541C96.9065 102.254 104.928 109.277 104.377 110.207C104.377 110.241 104.17 110.448 102.828 109.966L102.725 110.275C103.344 110.516 103.826 110.62 104.17 110.62C104.446 110.62 104.618 110.551 104.721 110.379C105.307 109.243 99.936 104.457 92.3278 97.7786Z"
        fill="#333333"
      />
      <path
        d="M101.04 99.8418C97.6659 97.3975 94.4643 95.0566 93.0872 93.1287C90.8495 89.9959 88.2331 87.9303 86.1675 86.2778C84.515 84.9696 83.2068 83.9368 82.5527 82.8007C82.1052 82.0434 82.0019 81.4581 82.2429 81.045C82.6216 80.3565 83.7921 80.2876 83.7921 80.2876V79.9434C83.7232 79.9434 82.415 80.0122 81.9331 80.8729C81.6232 81.4237 81.7265 82.1122 82.2429 82.9729C82.9314 84.1778 84.2741 85.2106 85.9265 86.5532C87.9921 88.2057 90.5741 90.2369 92.8118 93.3352C94.2233 95.2975 97.4594 97.6385 100.833 100.117C105.584 103.594 110.989 107.519 111.471 110.721C111.574 111.512 111.402 112.235 110.886 112.889L111.161 113.096C111.712 112.373 111.953 111.547 111.815 110.652C111.333 107.312 106.1 103.525 101.04 99.8418Z"
        fill="#333333"
      />
      <path
        d="M94.0495 90.8931C93.3954 90.1358 92.7413 89.4128 92.1905 88.7931C91.0544 87.5538 89.2987 86.521 87.7839 85.5915C86.3036 84.7308 84.9265 83.9046 84.6167 83.1816C84.5134 82.9751 84.5478 82.9062 84.5478 82.8718C84.72 82.6997 86.0282 83.2161 87.3019 83.939L87.4741 83.6292C86.6823 83.1816 84.7888 82.1488 84.3068 82.5964C84.1347 82.7341 84.1347 82.9751 84.3068 83.2849C84.6855 84.1112 86.0282 84.903 87.6118 85.8669C89.1265 86.762 90.8479 87.7948 91.9495 88.9997C92.5003 89.6194 93.12 90.3079 93.8085 91.0997C95.8741 93.4407 98.4217 96.367 102.036 99.7752C106.133 103.665 108.199 104.974 108.233 104.974L108.405 104.698C108.371 104.698 106.34 103.39 102.277 99.5342C98.6627 96.126 96.0807 93.2341 94.0495 90.8931Z"
        fill="#333333"
      />
      <path
        d="M88.5091 93.442C87.9582 92.9944 88.0615 92.8223 88.2336 92.478C88.5091 92.0305 88.8533 91.3764 87.5795 90.0682C85.4795 87.8993 78.6631 82.9075 78.5943 82.873L78.3877 83.1485C78.4565 83.1829 85.273 88.1747 87.3386 90.3092C88.4058 91.4108 88.1648 91.8928 87.9238 92.3059C87.6828 92.7534 87.5451 93.1321 88.2681 93.6829C89.6451 94.7502 103.106 106.249 105.206 109.037C107.271 111.757 107.96 113.96 107.994 113.995L108.339 113.891C108.304 113.788 107.616 111.619 105.516 108.831C103.416 106.076 89.8861 94.5436 88.5091 93.442Z"
        fill="#333333"
      />
      <path
        d="M50.0182 27.582C36.4886 27.582 25.541 38.5296 25.541 52.0592C25.541 65.5888 36.4886 76.5364 50.0182 76.5364C63.5478 76.5364 74.4954 65.5888 74.4954 52.0592C74.4954 38.5296 63.5134 27.582 50.0182 27.582ZM50.0182 72.6462C38.6575 72.6462 29.4312 63.4199 29.4312 52.0592C29.4312 40.6985 38.6575 31.4722 50.0182 31.4722C61.3789 31.4722 70.6052 40.6985 70.6052 52.0592C70.6052 63.4199 61.3789 72.6462 50.0182 72.6462Z"
        :fill="primaryColors.primary100"
      />
      <path
        d="M50.0189 31.2988C38.5893 31.2988 29.2598 40.594 29.2598 52.0579C29.2598 63.4875 38.5549 72.8171 50.0189 72.8171C61.4485 72.8171 70.778 63.5219 70.778 52.0579C70.778 40.594 61.4485 31.2988 50.0189 31.2988ZM50.0189 72.4728C38.7614 72.4728 29.604 63.3154 29.604 52.0579C29.604 40.8005 38.7614 31.6431 50.0189 31.6431C61.2763 31.6431 70.4338 40.8005 70.4338 52.0579C70.4338 63.3154 61.2763 72.4728 50.0189 72.4728Z"
        fill="#333333"
      />
      <path
        d="M50.0185 27.4082C36.42 27.4082 25.3691 38.4591 25.3691 52.0575C25.3691 65.6559 36.42 76.7068 50.0185 76.7068C63.6169 76.7068 74.6678 65.6559 74.6678 52.0575C74.6678 38.4591 63.5825 27.4082 50.0185 27.4082ZM50.0185 76.3281C36.6266 76.3281 25.7134 65.415 25.7134 52.0231C25.7134 38.6312 36.6266 27.718 50.0185 27.718C63.4103 27.718 74.3235 38.6312 74.3235 52.0231C74.3235 65.4494 63.4103 76.3281 50.0185 76.3281Z"
        fill="#333333"
      />
      <path
        d="M50.0196 28.6133C37.1097 28.6133 26.5752 39.1133 26.5752 52.0577C26.5752 64.9676 37.0753 75.5021 50.0196 75.5021C62.9639 75.5021 73.464 65.002 73.464 52.0577C73.464 39.1133 62.9295 28.6133 50.0196 28.6133ZM50.0196 75.1234C37.2818 75.1234 26.9195 64.761 26.9195 52.0232C26.9195 39.2855 37.2818 28.9231 50.0196 28.9231C62.7574 28.9231 73.1197 39.2855 73.1197 52.0232C73.1197 64.761 62.7574 75.1234 50.0196 75.1234Z"
        fill="#333333"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
const primaryColors = computed(() => {
  return getPrimaryColors();
});
</script>
